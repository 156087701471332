<template>
  <div class="wrapper">
    <div class="bg"></div>
    <div class="box">
      <div class="title">药易加_代理商端</div>
      <div class="form_box">
        <van-cell-group>
          <van-field v-model="phoneNumber" label="" placeholder="请输入登录账号" :left-icon="userIcon" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="password" label="" type="password" placeholder="请输入登录密码" :left-icon="pswIcon" />
        </van-cell-group>
        <div class="remeber" :class="[isChecked ? 'remebered': '']" @click="remeberPassword"><i class="icon"></i>记住密码</div>
      </div>
      <div class="btn" @click="onLogin">登 录</div>
    </div>
  </div>
</template>
<script>
// import { getQueryVariable } from '../utils/util'
import { Toast } from 'vant';
export default {
  components: {
  },
  data() {
    return {
      phoneNumber: '',
      password: '',
      userIcon: require('../assets/img/user.png'),
      pswIcon: require('../assets/img/psw_icon.png'),
      isChecked: true
    }
  },
  created() {
    let loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
    if (loginInfo) {
      this.phoneNumber = loginInfo.username;
      this.password = loginInfo.password;
    }
  },
  methods: {
    remeberPassword() {
      this.isChecked = !this.isChecked;
    },
    onLogin() {
      let params = {
        username: this.phoneNumber,
        password: this.password,
        systemId: 1
      }
      this.$http('post', '/auth/agentLogin', params).then(res => {
        console.log(res);
        if (res.code == 200) {
          if (this.isChecked) {
            localStorage.setItem('loginInfo', JSON.stringify(params));
          } else {
            localStorage.removeItem('loginInfo');
          }
          localStorage.setItem('userInfo', JSON.stringify(res.data));
          this.$router.push('/index');
        } else {
          Toast(res.msg);
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrapper {
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3.2rem;
    background: #3780ff;
    border-radius: 0px 0px 0.64rem 0.64rem;
  }
  .box {
    position: relative;
    z-index: 1;
    .title {
      padding: 0.86rem 0;
      text-align: center;
      color: #fff;
      font-size: 0.32rem;
    }
    .form_box {
      margin: 0 0.4rem;
      padding: 0.97rem 0.6rem 0.48rem;
      background: #fff;
      border-radius: 0.36rem 0.36rem 0.48rem 0.48rem;
      box-shadow: 0 0 10px #f5f7fb;
      /deep/ .van-cell-group {
        margin-bottom: 0.46rem;
      }
      /deep/ .van-cell {
        // padding: .35rem .3rem;
        /deep/ .van-field__left-icon {
          margin-right: 0.37rem;
        }
        background: #f8f8f8;
        .van-cell__title {
          font-size: 0.28rem;
          font-weight: bold;
          color: #333333;
        }
        .van-field__control {
          font-size: 0.28rem;
          color: #333333;
        }
        .van-field__control::-webkit-input-placeholder {
          color: #c5c5c5;
          font-size: 0.28rem;
        }
      }
      .remeber {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        font-size: 0.24rem;
        text-align: center;
        color: #8a8a8a;
        .icon {
          display: inline-block;
          margin-right: 0.12rem;
          width: 0.21rem;
          height: 0.21rem;
          background: #ffffff;
          border: 2px solid #c6cdd8;
          border-radius: 4px;
        }
      }
      .remebered {
        .icon {
          background: url("../assets/img/remeber.png") no-repeat center/ 100%
            100%;
        }
      }
    }
    .btn {
      margin: 0.78rem 0.4rem;
      padding: 0.28rem 0;
      font-size: 0.36rem;
      line-height: 0.36rem;
      color: #fff;
      background: #3780ff;
      border-radius: 8px;
      text-align: center;
    }
  }
}
</style>
